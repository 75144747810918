@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply px-4 cursor-pointer;
  }
}

/* Check if you can do this better */
.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.icon-ts {
  mask: url("./assets/svgs/typescript.svg") no-repeat center / contain;
}

.icon-py {
  mask: url("./assets/svgs/python.svg") no-repeat center / contain;
}

.icon-react {
  mask: url("./assets/svgs/react.svg") no-repeat center / contain;
}

.icon-tailwind {
  mask: url("./assets/svgs/tailwind.svg") no-repeat center / contain;
}

.icon-amplify {
  mask: url("./assets/svgs/amplify.svg") no-repeat center / contain;
}
